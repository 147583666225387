var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('titleBar',{attrs:{"title":" ","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":false,"actions":[
      {
        icon: 'search',
        to: '/explore',
        hidden: false,
        hiddenTablet: false,
      },
      {
        icon: 'bell',
        to: '/notifications',
        hidden: false,
        hiddenTablet: false,
      },
      /*  {
        icon: 'comment',
        to: '/messages',
        hidden: false,
        hiddenTablet: false,
      },*/
    ]}}),_vm._v(" "),_c('div',{staticClass:"section"},[_c('botsTabs',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_vm._v(" "),_c('exploreTabs')],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"section"},[_c('div',{staticClass:"container",staticStyle:{"max-width":"1000px"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Talk with fun and helpful AIs")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchBots.apply(null, arguments)}}},[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Search hundreds of AIs","icon":"fas fa-search","expanded":"","rounded":"","size":"is-large"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchBots.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"cat-buttons",staticStyle:{"margin-bottom":"45px"}},_vm._l((_vm.cats),function(cat){return _c('router-link',{key:cat.id,staticClass:"button is-primary is-light",attrs:{"to":`/explore-bots/${cat.value}`},on:{"click":function($event){return _vm.searchBots(cat.value)}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{class:'icon far fa-' + cat.icon})]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(cat.label))])])}),1)])]),_vm._v(" "),_c('FeaturedBots',{attrs:{"blocks":_vm.blocks}}),_vm._v(" "),_c('div',{staticClass:"section"},[(!_vm.loadingProj)?_c('div',{staticClass:"container home"}):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-4 is-hidden-mobile"},[_c('img',{staticStyle:{"max-height":"200px","margin":"auto","display":"block"},attrs:{"src":"https://cdn1.onlybots.cc/path/to/upload/folderehloTDTbng___face1.png"}})])
}]

export { render, staticRenderFns }